/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDInput from "../../../../components/MDInput";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import Load from "../../../../examples/Load";
// Material Dashboard 2 React examples
import DataTable from "../../../../examples/Tables/DataTable";

// Data
import data from "../../../../layouts/dashboard/components/Projects/data";
import { url_serversq } from "../../../../context/var";

function Projects({ das, packs, actionUPD }) {
  const [isAdd, addAction] = useState(() => { return false });
  const [isload, setLoad] = useState(() => { return false });
  const [copy_key, setCopy_key] = useState(() => { return "" });
  const [copy_img, setCopy_img] = useState(() => { return "" });
  const [ptype, setPtype] = useState(() => { return false });
  const [prop, setProp] = useState(() => {
    return {
      api_key: "",
      secret: "",
      ida: "",
    }
  })
  const handleChange = (event) => {
    setPtype(event.target.value);
    let t = packs.find(el => el.id == event.target.value);
    setCopy_key(t.copy_key);
    setCopy_img(t.copy_img);
  };

  const changeInputProp = event => {
    event.persist()
    setProp(prev => {
      return { ...prev, [event.target.name]: event.target.value, }
    })
  }

  const handleSave = (event) => {
    event.preventDefault();
    if (ptype == "" || prop.api_key == "" || prop.secret == "" || prop.ida == "") {
      alert("Пожалуйста заполните все данные");
    } else {
      let s_key = localStorage.getItem("s_key");
      let req = { ...prop, s_key: s_key, ptype: ptype }
      setLoad(true);
      axios.post(url_serversq + "/addpack.php", req).then(res => {

        if (res.data !== false) {
          alert(res.data);
          addAction(false);
          actionUPD(false);
          setLoad(false);
        } else {
          alert("Ошибка добавления, неправильно введенные данные");
          setLoad(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setLoad(false);
      })
    }
  }
  if (!isload) {
    if (!isAdd) {
      const { columns, rows } = data({ das, setLoad });


      return (
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <MDBox>
              <MDTypography variant="h6" gutterBottom>
                Продукты
              </MDTypography>
            </MDBox>
            <MDBox color="text" px={2}>
              <Link onClick={() => {
                setProp({ api_key: "", secret: "", ida: "", }); setPtype(false); addAction(true)
              }}>
                <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" >add</Icon>
              </Link>
            </MDBox>
          </MDBox>
          <MDBox>
            <DataTable
              table={{ columns, rows }}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
            />
          </MDBox>
        </Card>
      );
    } else {
      var mi = [];

      packs.forEach(element => {
        mi.push(<MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>)
      });
      return (
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <MDBox>
              <MDTypography variant="h6" gutterBottom>
                Продукты (добавление)
              </MDTypography>
            </MDBox>
            <MDBox color="text" px={2}>
              <Link onClick={handleSave}>
                <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" >
                  save
                </Icon>
              </Link>&nbsp;&nbsp;
              <Link onClick={() => { addAction(false) }}>
                <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" >
                  logout
                </Icon>
              </Link>
            </MDBox>
          </MDBox>
          <MDBox p={2}>
            <MDBox>
              <MDBox display="flex" py={1} pr={2} justifyContent="space-between">
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Тип пакета: &nbsp;&nbsp;
                </MDTypography>
                <Select
                  value={ptype}
                  label="Тип пакета"
                  onChange={handleChange}
                  fullWidth
                >
                  {mi}
                </Select>
              </MDBox>
              <MDBox display="flex" py={1} pr={2} justifyContent="space-between">
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Мастер-трейдера ID: &nbsp;&nbsp;
                </MDTypography>
                <MDInput type="text" variant="standard" fullWidth name="ida" value={copy_key} disable />
              </MDBox>

              <MDBox display="flex" py={1} pr={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Ваш ID на бирже: &nbsp;&nbsp;
                </MDTypography>
                <MDInput type="text" variant="standard" fullWidth name="ida" value={prop.ida} onChange={changeInputProp} />
              </MDBox>
              <MDBox display="flex" py={1} pr={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Api key: &nbsp;&nbsp;
                </MDTypography>
                <MDInput type="text" variant="standard" fullWidth name="api_key" value={prop.api_key} onChange={changeInputProp} />
              </MDBox>
              <MDBox display="flex" py={1} pr={2}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Secret: &nbsp;&nbsp;
                </MDTypography>
                <MDInput type="text" variant="standard" fullWidth name="secret" value={prop.secret} onChange={changeInputProp} />
              </MDBox>
              <MDBox display="flex" py={1} pr={2}>
                <a href={copy_img} target="_blank" >
                  <MDBox component="img" src={copy_img} alt="Load..." width="100%" />
                </a>
                {/* <img src={copy_img} onerror = "this.style.display = 'none'"/> */}
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      );

    }
  } else { return (<Load />); }
}

export default Projects;
