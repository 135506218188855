import { Button, Card, Grid, StyledEngineProvider } from '@mui/material';
import React, { useState } from 'react';
import Tree from 'react-d3-tree';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import Load from '../../examples/Load';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import axios from 'axios';
import { url_serversq } from "../../context/var";
import DataTable from '../../examples/Tables/DataTable';
import data from "../../layouts/admin/data";
import SelectInput from '@mui/material/Select/SelectInput';

function Admin() {
    const [das, setDas] = useState(() => {
        return false
    });

    const btnUpd = (id) => {
        let s_key = localStorage.getItem('s_key');
        axios.post(url_serversq + "/updatewalt.php", {
            key: 'dl;fkh849yfdg2kgnaqot',
            s_key: s_key,
            id: id,
        }).then(res => {
            if (res.data != false) {
                das.packs.forEach(element => {
                    if (element.id == id)
                        element.balance = res.data.balance;
                });
            } else {
                alert("Ошибка связи с сервером");
                window.location.href = "/"
            }
        }).catch(() => {
            alert("Ошибка связи с сервером");
            window.location.href = "/"
        })
    };
    function sleep(ms) {
        ms += new Date().getTime();
        while (new Date() < ms) { }
    }

    const updAll = () => {
        var i = 0;
        das.packs.forEach(element => {
            i += 1;
            console.log(element.id);
            btnUpd(element.id);
            if (i == 5) {
                i = 0;
                sleep(40000);
            }
        });
        alert('Done');
    };

    if (!das) {
        let s_key = localStorage.getItem('s_key');
        axios.post(url_serversq + "/getAdmin.php", {
            s_key: s_key,
            key: 'dl;fkh849yfdg2kgnaqot',
        }).then(res => {
            if (res.data != false) {

                setDas(res.data);
            } else {
                alert("Ошибка связи с сервером");
                window.location.href = "/"
            }
        }).catch(() => {
            alert("Ошибка связи с сервером");
            window.location.href = "/"
        })
    }

    if (das) {
        const { columns, rows } = data({ das, btnUpd });

        return (
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox mb={2}>
                    <Button onClick={updAll}>Обновить все</Button>
                </MDBox>
                <Card>
                    <MDBox>
                        <DataTable
                            table={{ columns, rows }}
                            showTotalEntries={false}
                            isSorted={false}
                            noEndBorder
                            entriesPerPage={false}
                        />

                    </MDBox>
                </Card>
                <Footer />
            </DashboardLayout>


        );
    } else {
        return (<div style={{
            width: '100vw',
            height: '100vh',
        }}><Load /></div>);
    }
}


export default Admin;

{/* <Tree
orientation="vertical"
collapsible={true}
useCollapseData={true}
initialDepth={1}
nodeSize={{ x: 180, y: 180 }}
transitionDuration={700}
zoomable={true}

data={orgChart} /> */}