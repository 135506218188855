/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import MasterCard from "../../examples/Cards/MasterCard";
import DefaultInfoCard from "../../examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import Withdrowal from "../../layouts/billing/components/Withdrowal";
import WithdrowalHistory from "../../layouts/billing/components/WithdrowalHistory";
import BillingInformation from "../../layouts/billing/components/BillingInformation";
import Transactions from "../../layouts/billing/components/Transactions";
import BonusTransactions from "../../layouts/billing/components/BonusTransactions";
import axios from 'axios';
import { useState } from "react";
import Load from "../../examples/Load";
import { url_serversq } from "../../context/var";

function Billing() {
  const [das, setDas] = useState(() => {
    return false
  });

  if (!das) {

    let s_key = localStorage.getItem('s_key');
    axios.post(url_serversq + "/getbill.php", {
      s_key: s_key,
    }).then(res => {
      if (res.data != false) {
        setDas(res.data);
      } else {
        alert("Ошибка связи с сервером");
        window.location.href = "/"
      }
    }).catch(() => {
      alert("Ошибка связи с сервером");
      window.location.href = "/"
    })
  }

  if (das) {
    let bonus = "$" + das.bonus;
    let future = "$" + das.future;
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={2}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} xl={6}>
                    <DefaultInfoCard
                      icon="account_balance"
                      title="Бонусный счет"
                      description=""
                      value={bonus}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <DefaultInfoCard
                      icon="leaderboard"
                      title="Средства в торговле"
                      description=""
                      value={future}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Withdrowal />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <BonusTransactions />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Transactions />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  } else {
    return (<div style={{
      width: '100vw',
      height: '100vh',
    }}><Load /></div>);
  }
}

export default Billing;
