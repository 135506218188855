/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDSnackbar from "../../components/MDSnackbar";
import axios from 'axios';
import { useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ReportsBarChart from "../../examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Load from "../../examples/Load";

// Data
import reportsBarChartData from "../../layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "../../layouts/dashboard/data/reportsLineChartData";
import { url_serversq } from "../../context/var";

// Dashboard components
import Projects from "../../layouts/dashboard/components/Projects";
import StructureOverview from "./components/StructureOverview";

function Dashboard() {

  const [das, setDas] = useState(() => {
    return false
  });
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const [infoSB, setInfoSB] = useState(false);

  const renderInfoSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Информация"
      content="Реферальная ссылка скопирована в буфер обмена"
      dateTime=""
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  if (!das) {
    console.log(url_serversq + "/getdas.php");
    let s_key = localStorage.getItem('s_key');
    axios.post(url_serversq + "/getdas.php", {
      s_key: s_key,
    }).then(res => {
      if (res.data != false) {
        setDas(res.data);
      } else {
        alert("Ошибка связи с сервером");
        window.location.href = "/"
      }
    }).catch(() => {
      alert("Ошибка связи с сервером");
      window.location.href = "/"
    })
  }

  if (das) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="money"
                  title="USDT"
                  count={das.bonus}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Бонусные средства",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="USDT"
                  count={das.future}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Средства в торговле",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="USDT"
                  count={das.profit}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Профит",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="количество"
                  count={das.totalref}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Рефералы",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Projects das={das.packs} packs={das.products} actionUPD={setDas} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <StructureOverview das={das.strcture} openInfoSB={openInfoSB} />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {renderInfoSB}
        <Footer />
      </DashboardLayout>
    );
  } else {
    return (<div style={{
      width: '100vw',
      height: '100vh',
    }}><Load /></div>);
  }
}

export default Dashboard;
