/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";
import { useState } from "react";
// prop-types is library for typechecking of props
import {url_serversq} from "../../../../context/var";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import axios from 'axios';
import Load from "../../../../examples/Load";

// Material Dashboard 2 React base styles
import MDInput from "../../../../components/MDInput";

function ProfileInfoCard({ title, info, shadow }) {
  const [isEdit, setisEdit] = useState(() => { return false });
  const [isLoad, setLoad] = useState(() => { return false });

  const [eInfo, seteInfo] = useState(() => { return { ...info } });
  const [eKey, seteKey] = useState(() => {
    return {
      eKey: false,
      eKeye: false,
    }
  });

  const changeInputRegister = event => {
    event.persist()
    var l = eInfo[event.target.name].label;
    var c = eInfo[event.target.name].canEdit;
    var s = eInfo[event.target.name].show;
    seteInfo(prev => {
      return {
        ...prev,
        [event.target.name]: { value: event.target.value, canEdit: c, label: l, show: s, }
      }
    })
  }

  const changeInputeKey = event => {
    event.persist()
    seteKey(prev => {
      return { ...prev, eKey: event.target.value, }
    });

  }


  const changeedit = () => {
    seteInfo(() => { return { ...info } });
    setisEdit(prev => { return !prev });
    seteKey({ eKey: false, eKeye: false, })
  }


  const submitChackin = () => {

    if (eKey.eKey == false) {
      setLoad(true);
      axios.post(url_serversq+"/sendkey.php", {
        email: info.email.value,
      }).then(res => {
        if (res.data !== false) {
         
          seteKey(prev => { return { eKey: "", eKeye: res.data, } })
          setLoad(false);
        } else {
          alert("There is already a user with this email");
          setLoad(false);
        }
      }).catch(() => {
        alert("An error occurred on the server");
        setLoad(false);
      })

    } else {
      if (eKey.eKey == eKey.eKeye) {
        seteKey(prev => { return { eKey: false, eKeye: false, } });

        let s_key = localStorage.getItem("s_key");
        let req = { ...eInfo, s_key: s_key }
        setLoad(true);
        axios.post(url_serversq+"/setprofile.php", req).then(res => {
         if (res.data !== false) {
            alert(res.data);
            setisEdit(false);
          } else {
            setLoad(false); alert("An error occurred on the server");
          }
        }).catch(() => {
          setLoad(false); alert("An error occurred on the server");
        })
      } else { alert("Код подтверждения не совпадает") };

    }

  }

  var renderItems = [];

  for (var key in eInfo) {

    if (eInfo[key].show !== false)
      if (isEdit && eInfo[key].canEdit) {
        renderItems.push(
          <MDBox key={key} display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {eInfo[key].label}: &nbsp;&nbsp;
            </MDTypography>
            <MDInput type="text" variant="standard" fullWidth name={key} value={eInfo[key].value} onChange={changeInputRegister} />
          </MDBox>)
      } else {
        renderItems.push(
          <MDBox key={key} display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {eInfo[key].label}: &nbsp;&nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text" maxWidth="100%">
              &nbsp;{eInfo[key].value}
            </MDTypography>     </MDBox>)
      }
  };
  if (isEdit) {
    if (eKey.eKey !== false)
      renderItems.push(
        <MDBox key={"key"} display="flex">
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            Email key: &nbsp;&nbsp;
          </MDTypography>
          <MDInput type="text" variant="standard" fullWidth value={eKey.eKey} onChange={changeInputeKey} /> &nbsp;&nbsp;
        </MDBox>
      );
    if (!isLoad)
      renderItems.push(
        <MDBox key={"key1"} mt={4} mb={1}>
          <MDButton variant="gradient" id="subBTN" color="info" onClick={submitChackin} fullWidth >сохранить</MDButton>
        </MDBox>
      ); else
      renderItems.push(
        <MDBox key={"key1"} mt={4} mb={1}>
          <Load />
        </MDBox>
      );
  }

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }} >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        <MDTypography component={Link} onClick={changeedit} variant="body2" color="secondary">
          <Tooltip title={"Изменить"} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2} >
        <MDBox >
          {renderItems}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard


export default ProfileInfoCard;
