/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React ./layouts
import Dashboard from "./layouts/dashboard";
import Tables from "./layouts/tables";
import Billing from "./layouts/billing";
import Structure from "./layouts/structure";
import RTL from "./layouts/rtl";
import Notifications from "./layouts/notifications";
import Profile from "./layouts/profile";
import Home from "./layouts/home";
import Instruction from "./layouts/instruction";
import Admin from "./layouts/admin";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
 {
    type: "collapse",
    name: "Главная",
    key: "Главная",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <Home />,
    show: true,
    onlylogin: true,
  },  
  {
    type: "collapse",
    name: "Кабинет",
    key: "кабинет",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    show: true,
    onlylogin: true,
  },
 
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
    show: false,
    onlylogin: true,
  },
  {
    type: "collapse",
    name: "Финансы",
    key: "финансы",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/finance",
    component: <Billing />,
    show: true,
    onlylogin: true,
  },
  {
    type: "collapse",
    name: "Структура",
    key: "Структура",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/structure",
    component: <Structure />,
    show: true,
    onlylogin: true,
  },
  {
    type: "collapse",
    name: "Оповещения",
    key: "оповещения",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    show: true,
    onlylogin: true,
  },
  {
    type: "collapse",
    name: "Профиль",
    key: "профиль",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    show: true,
    onlylogin: true,
  },  {
    type: "collapse",
    name: "Инструкции",
    key: "Инструкции",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/instruction",
    component: <Instruction />,
    show: true,
    onlylogin: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
    show: false,
    onlylogin: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "registration",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/registration/:referal",
    component: <SignUp />,
    show: false,
    onlylogin: false,
  },
  {
    type: "collapse",
    name: "Admin",
    key: "Admin",
    icon: <Icon fontSize="small">admin</Icon>,
    route: "/admin",
    component: <Admin />,
    show: false,
    onlylogin: true,
  },
];

export default routes;
