/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";

// Material Dashboard 2 React example components
import TimelineItem from "../../../../examples/Timeline/TimelineItem";

function StructureOverview({ das, openInfoSB }) {

  const copyLink = event => {
    event.persist()

    navigator.clipboard.writeText(das.reflink)
      .then(() => {
        
      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
      openInfoSB();
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Структура
        </MDTypography>
        <MDBox >
          <MDButton variant="gradient" color="info" onClick={copyLink} fullWidth >реферальная ссылка</MDButton>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="error"
          icon="1"
          title={das.l1sum}
          dateTime={das.l1count}
        />
        <TimelineItem
          color="error"
          icon="2"
          title={das.l2sum}
          dateTime={das.l2count}
        />
        <TimelineItem
          color="warning"
          icon="3"
          title={das.l3sum}
          dateTime={das.l3count}
        />
        <TimelineItem
          color="warning"
          icon="4"
          title={das.l4sum}
          dateTime={das.l4count}
        />
        <TimelineItem
          color="success"
          icon="5"
          title={das.l5sum}
          dateTime={das.l5count}
        />
        <TimelineItem
          color="success"
          icon="6"
          title={das.l6sum}
          dateTime={das.l6count}
        />
        <TimelineItem
          color="success"
          icon="7"
          title={das.l7sum}
          dateTime={das.l7count}
          lastItem
        />
      </MDBox>
    </Card>
  );
}

export default StructureOverview;
