/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import axios from 'axios';
import validator from 'validator';

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../../layouts/authentication/components/BasicLayout";

// Images
import bgImage from "../../../assets/images/bg-sign-in-basic.jpeg";
import { url_serversq } from "../../../context/var";
import { flexbox } from "@mui/system";

function Basic() {

  const [register, setRegister] = useState(() => {
    return {
      email: "",
      password: "",
    }
  });

  const changeInputRegister = event => {
    event.persist()
    setRegister(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      }
    })
  }

  const show_hide_password = () => {
    var input = document.getElementById('password-input');
    if (input.getAttribute('type') == 'password') {
      input.setAttribute('type', 'text');
    } else {
      input.setAttribute('type', 'password');
    }
    return false;
  }

  const submitChackin = (event) => {
    event.preventDefault();

    if (!validator.isEmail(register.email)) {
      alert("You did not enter email")
    } else {
      document.getElementById("subBTN").setAttribute('disabled', true);
      axios.post(url_serversq + "/auth.php", {
        email: register.email,
        password: register.password,
      }).then(res => {
        if (res.data !== false) {
          localStorage.setItem("TimeLogOn", Date.now());
          localStorage.setItem("s_key", res.data);
          //console.log(res.data);
          window.location.href = "/"
        } else {
          alert("Ошибка авторизации");
          document.getElementById("subBTN").removeAttribute('disabled', false)
        }
      }).catch(() => {
        alert("An error occurred on the server");
        document.getElementById("subBTN").removeAttribute('disabled', false)
      })

    }
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Авторизация
          </MDTypography>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth name="email" value={register.email} onChange={changeInputRegister} />
            </MDBox>
            <MDBox mb={2} style={{display: "flex", alignItems: "center",}} >
              <MDInput pr={2} type="password" label="Password" fullWidth name="password" id="password-input" value={register.password} onChange={changeInputRegister} />
              <Link onClick={show_hide_password} >   <VisibilityIcon/></Link>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" id="subBTN" color="info" fullWidth onClick={submitChackin}>
                Вход
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
