/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { Card, Grid, Icon } from "@mui/material";
import t1 from "../../assets/images/home/1.jpg";
import t2 from "../../assets/images/home/2.jpg";
import t3 from "../../assets/images/home/3.jpg";
import t4 from "../../assets/images/home/4.jpg";

function Overview() {

    return (

        <DashboardLayout>
            <DashboardNavbar />
            <MDBox position="relative" mb={5}>

                <Card
                    sx={{
                        position: "relative",
                        mx: 3,
                        py: 2,
                        px: 2,
                    }}
                >

                    <MDBox pl={1} pr={1}>
                        <Grid item >
                            <MDBox component="img" src={t1} width={"50%"} />
                            <MDBox component="img" src={t2} width={"50%"} />
                        </Grid>
                        <Grid item >
                            <MDBox component="img" src={t3} maxWidth={"100%"} />
                        </Grid>
                        <MDTypography variant="h4" fontWeight="medium" mt={3} mb={3} align={"center"}>
                            ПРЕИМУЩЕСТВА.</MDTypography>
                        <MDTypography variant="h6" fontWeight="medium">
                            <Icon fontSize={'medium'} color={'info'}>check</Icon>  Стабильный рост депозита более 1 года</MDTypography>
                        <MDTypography variant="caption">
                            Система успешно прошла deep backtesting на периоде 1 / 3 / 7,5 / 12 / 18 / 24 / 30 / 36 месяцев. </MDTypography>

                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            <Icon fontSize={'medium'} color={'info'}>check</Icon>  Низкое кредитное плечо</MDTypography>
                        <MDTypography variant="caption">
                            Торговля осуществляется с применением плеча х10</MDTypography>

                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            <Icon fontSize={'medium'} color={'info'}>check</Icon>  Низкая просадка</MDTypography>
                        <MDTypography variant="caption">
                            Максимальная просадка по портфелю за весь период тестирования (34 месяца) составила 16,47%.  </MDTypography>

                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            <Icon fontSize={'medium'} color={'info'}>check</Icon>  Прекрасные технические характеристики</MDTypography>
                        <MDTypography variant="caption">
                            Система имеет RR 1.5. То есть takeprofit выше, чем stoploss в 1.5 раза. При этом количество прибыльных сделок 50-60%.  </MDTypography>

                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            <Icon fontSize={'medium'} color={'primary'}>stop</Icon>  Ликвидация</MDTypography>
                        <MDTypography variant="caption">
                            За все периоды тестирования ОТСУТСТВУЕТ ликвидация торгового баланса. </MDTypography>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            <Icon fontSize={'medium'} color={'primary'}>stop</Icon>  Усреднения</MDTypography>
                        <MDTypography variant="caption">
                            Является трендовым алгоритмом. Мы НЕ ИСПОЛЬЗУЕМ систему Мартингейла в торговле. </MDTypography>

                        <MDTypography variant="h6" mt={3}>
                            Необходимые технические характеристики: </MDTypography>

                        <MDTypography variant="subtitle2" fontWeight="medium" mt={2}>
                            <Icon color={'info'}>circle</Icon>  На данный момент соотношение количества тейков и стопов примерно одинаковое. Winrate: takeprofit 57%, stoploss 43%.</MDTypography>
                        <MDTypography variant="subtitle2" fontWeight="medium" mt={2}>
                            <Icon color={'info'}>circle</Icon>  Соотношение значения тейков и стопов (risk reward): 1,5. То есть takeprofit выше stoploss в 1,5 раза. </MDTypography>

                        <Grid item mt={3} mb={4}>
                            <MDBox component="img" src={t4} maxWidth={"100%"} />
                        </Grid>

                        <MDTypography variant="h6" >
                            Рекомендация от команды: </MDTypography>

                        <MDTypography variant="subtitle2" fontWeight="medium" mt={2}>
                            <Icon color={'info'}>circle</Icon>  Мы позиционируем торговую систему как продукт со среднесрочной/долгосрочной отработкой. Здесь не получится заработать много денег быстро. Рассматривать локальные неудачи нецелесообразно. Алгоритм показывает отличные результаты на дистанции. </MDTypography>
                        <MDTypography variant="subtitle2" fontWeight="medium" mt={2}>
                            <Icon color={'info'}>circle</Icon>  Вы вправе регулировать размер своего депозита по своему усмотрению. Но мы рекомендуем придерживаться созидательной модели поведения: наблюдать естесственный ход событий и следовать по пути наименьшего сопротивления. Осуществлять вывод средств (полный или частичный) только при достижении заданных целей, чтобы не нарушать процесс капитализации процентов. </MDTypography>
                        <MDTypography variant="subtitle2" fontWeight="medium" mt={2}>
                            <Icon color={'info'}>circle</Icon>  Для новых партнеров: уделить внимание механизму плавного входа в торговлю. Это крайне важное звено торговой системы.  </MDTypography>

                    </MDBox>
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );

}

export default Overview;
