/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";
import MDAvatar from "../../../../../components/MDAvatar";
import MDProgress from "../../../../../components/MDProgress";
import axios from 'axios';

// Material Dashboard 2 React base styles
import MDInput from "../../../../../components/MDInput";
import { url_serversq } from "../../../../../context/var";

export default function data({ das, setLoad }) {



  const Company = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1} >
        {name}
      </MDTypography>
    </MDBox>
  );


  const btnEdit = (event) => {
    event.preventDefault();

    let s_key = localStorage.getItem("s_key");
    let req = { s_key: s_key, ptype: event.target.name }

    setLoad(true);
    axios.post(url_serversq + "/sendset.php", req).then(res => {

      if (res.data !== false) {
        alert(res.data);
        setLoad(false);
      } else {
        alert("Ошибка добавления, неправильно введенные данные");
        setLoad(false);
      }
    }).catch(() => {
      alert("An error occurred on the server");
      setLoad(false);
    })
  }


  var rw = [];

  das.forEach(element => {
    rw.push({
      name: <Company name={element.name} />,
      ndep: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          ${element.deposit}
        </MDTypography>
      ),
      tdep: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          ${element.balance}
        </MDTypography>
      ),
      status: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {element.status}
        </MDTypography>
      ),
      action: (
        <MDBox width="8rem" textAlign="left">
          <MDButton variant="gradient" id="subBTN" color="info" name={element.id} onClick={btnEdit} fullWidth >настройки</MDButton>
        </MDBox>
      ),
    });
  });


  return {
    columns: [
      { Header: "Название", accessor: "name", width: "45%", align: "left" },
      { Header: "Начальный депозит", accessor: "ndep", width: "10%", align: "left" },
      { Header: "Текущий баланс", accessor: "tdep", align: "center" },
      { Header: "Статус", accessor: "status", align: "center" },
      { Header: "Действия", accessor: "action", align: "center" },
    ],
    rows: rw

  };
}
