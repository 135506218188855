/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import axios from 'axios';
import { useState } from "react";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ProfileInfoCard from "../../examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "../../examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "../../layouts/instruction/components/Header";
import Load from "../../examples/Load";
import PlatformSettings from "../../layouts/instruction/components/PlatformSettings";

// Images
import t1 from "../../assets/images/instruction/1.png";
import t2 from "../../assets/images/instruction/2.png";
import t3 from "../../assets/images/instruction/3.png";
import t4 from "../../assets/images/instruction/4.png";
import t5 from "../../assets/images/instruction/5.png";
import t6 from "../../assets/images/instruction/6.png";
import t7 from "../../assets/images/instruction/7.png";
import t8 from "../../assets/images/instruction/8.png";
import t9 from "../../assets/images/instruction/9.png";
import t10 from "../../assets/images/instruction/10.png";
import t11 from "../../assets/images/instruction/11.png";
import t12 from "../../assets/images/instruction/12.png";

function Overview() {

    return (

        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />
            <Header>
                <MDBox mt={5} mb={3} >
                        <MDTypography variant="h5" fontWeight="medium" mt={2}>
                            Для начала необходимо зарегистрироваться на бирже Finandy.</MDTypography>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            После регистрации обратите внимание на переключатель в левом верхнем углу страницы.</MDTypography>
                        <MDBox component="img" src={t1}  mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            Выберите торговую площадку Finandy.</MDTypography>
                        <MDBox component="img" src={t2}  mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            Пополните спотовый кошелек на необходимую сумму. В нижней части экрана выберите монету USDT #1 и сеть #2, в зависимости от Вашего кошелька. Сгенерируется адрес #3, на который необходимо совершить перевод.                        </MDTypography>
                        <MDBox component="img" src={t3} maxWidth={'100%'} mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            После поступления денег на счет переведите сумму со спотового кошелька на кошелек фьючерс. Нажмите кнопку перевод #1 и укажите сумму #2.</MDTypography>
                        <MDBox component="img" src={t4}  maxWidth={'100%'} mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            В левом верхнем углу смените Spot на Future.</MDTypography>
                        <MDBox component="img" src={t5}  maxWidth={'100%'} mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            В левом нижнем углу нажмите на кнопку настройки профиля #1, в разделе Api ключ #2 укажите любое название #3 и нажмите кнопку создать API ключ.</MDTypography>
                        <MDBox component="img" src={t6}  maxWidth={'100%'} mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            Скопируйте API ключ и секрет.</MDTypography>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            Перейдите в Ваш кабинет компании SoNat. Создайте новый продукт #1.</MDTypography>
                        <MDBox component="img" src={t7}  maxWidth={'100%'} mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            Укажите тип продукта #1 и вставьте API ключ #2 и секрет #3. Скопируйте Мастер-трейдер ID #4.</MDTypography>
                        <MDBox component="img" src={t8} maxWidth={'100%'} mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            Перейдите на сайт Finandy и нажмите кнопкуКопи-трейдинг #1 и перейдите во вкладку настройки #2. Вставьте Мастер-трейдер ID #3 и нажмите кнопку подключить.</MDTypography>
                        <MDBox component="img" src={t9} maxWidth={'100%'}  mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            В строке Макс.объём всех позиций укажите 40% от суммы.</MDTypography>
                        <MDBox component="img" src={t10} maxWidth={'100%'}  mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            В левой части экрана скопируйте Ваш ID.</MDTypography>
                        <MDBox component="img" src={t11} maxWidth={'100%'}  mt={2}/>
                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                            Вернитесь на сайт SoNat, вставьте Ваш ID и сохраните.</MDTypography>
                        <MDBox component="img" src={t12} maxWidth={'100%'}  mt={2}/>

                   
                </MDBox>


            </Header>
            <Footer />
        </DashboardLayout>
    );

}

export default Overview;
