/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import MDInput from "../../../../components/MDInput";

// Images
import usdtLogo from "../../../../assets/images/logos/usdtlogo.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "../../../../context";

function Withdrowal() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card id="delete-account">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Вывод бонусов
        </MDTypography>
        <MDButton variant="gradient" color="dark">
          &nbsp;Создать заявку
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>

            <MDBox
              borderRadius="lg"
              p={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <MDBox >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" mr={2}>
                  Сумма вывода: &nbsp;&nbsp;
                </MDTypography>
                <MDInput type="number" variant="standard" fontWeight="bold" /* value={eKey.eKey} onChange={changeInputeKey} */ />
              </MDBox>
              <MDBox display="flex">
                <MDBox component="img" src={usdtLogo} alt="USDT Wallet" width="40px" mr={2} />
                <MDInput type="text" variant="standard" fullWidth /* value={eKey.eKey} onChange={changeInputeKey} */ />
              </MDBox>
              <MDBox display="flex" flexDirection="column" mt={3}>
                <MDTypography variant="caption" color="text" fontWeight="regular">
                  Комиссия за вывод 1 USDT
                </MDTypography>
                <MDTypography variant="caption" color="text" fontWeight="regular">
                  Заявки обрабатываются в течении 24 часов.
                </MDTypography>
              </MDBox>
            </MDBox> 
          </Grid>

        </Grid>
      </MDBox>
    </Card>
  );
}

export default Withdrowal;
