import { Card, Grid } from '@mui/material';
import React, { useState } from 'react';
import Tree from 'react-d3-tree';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import Load from '../../examples/Load';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import axios from 'axios';
import { url_serversq } from "../../context/var";


function Structure() {
    const [orgChart, setorgChart] = useState(() => {
        return false
    });

    if (!orgChart) {
        let s_key = localStorage.getItem('s_key');
        axios.post(url_serversq + "/getStructure.php", {
            s_key: s_key,
        }).then(res => {
            if (res.data != false) {
                setorgChart(res.data);
            } else {
                alert("Ошибка связи с сервером");
                window.location.href = "/"
            }
        }).catch(() => {
            alert("Ошибка связи с сервером");
            window.location.href = "/"
        })
    }

    if (orgChart) {
        const renderForeignObjectNode = ({
            nodeDatum,
            toggleNode,
            foreignObjectProps
        }) => (
            <g>

                {/* `foreignObject` requires width & height to be explicitly set. */}
                <foreignObject {...foreignObjectProps} x={-90} y={-50} onClick={toggleNode}>
                    <div style={{ border: "1px solid black", backgroundColor: "rgb(255 188 188)" ,borderRadius: '10px' }} >
                        <p style={{ textAlign: "center",fontSize: "14px" }}>{nodeDatum.name}</p>
                        <p style={{ textAlign: "right" }}>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'USD' }).format(nodeDatum.deposite ? nodeDatum.deposite : 0)}</p>
                        {nodeDatum.children && (
                            <button style={{ width: "100%" }} onClick={toggleNode}>
                                {nodeDatum.__rd3t.collapsed ? "Показать" : "Скрыть"}
                            </button>
                        )}
                    </div>
                </foreignObject>
            </g>
        );
        const nodeSize = { x: 180, y: 190 };
        const foreignObjectProps = { width: nodeSize.x - 5, height: nodeSize.y};
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <Card >
                    <MDBox style={{ height: '80vh', width: '100%' }}>
                        <Tree
                            data={orgChart}
                            pathFunc="step"
                            nodeSize={nodeSize}
                            initialDepth={1}
                            renderCustomNodeElement={(rd3tProps) =>
                                renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                            }
                            orientation="vertical"
                        />
                    </MDBox>
                </Card>
                <Footer />
            </DashboardLayout>


        );
    } else {
        return (<div style={{
            width: '100vw',
            height: '100vh',
        }}><Load /></div>);
    }
}


export default Structure;

{/* <Tree
orientation="vertical"
collapsible={true}
useCollapseData={true}
initialDepth={1}
nodeSize={{ x: 180, y: 180 }}
transitionDuration={700}
zoomable={true}

data={orgChart} /> */}